
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.countdown {
    .unit-wrap {
        color: $secondary-color;
        display: inline-block;
        font-size: 1em;
        text-align: center;
        min-width: 75px;
        margin: 0 0.5em;
        .unit {
            color: $primary-color;
            display: block;
            font-size: 2em;
            font-weight: bold;
        }
    }
}

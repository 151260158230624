
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.convert-credits-page {
    font-size: 1.1em;
}
.distribution-subtitle-text {
    word-break: break-all;
}
.text-primary {
    color: $primary-color;
}
